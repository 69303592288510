const config = {
  STRIPE_KEY: process.env.REACT_APP_PUBLIC_STRIPE_KEY,
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: process.env.REACT_APP_API_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_URL
  },
  cognito: {
    REGION: process.env.REACT_APP_API_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_API_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_API_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_API_COGNITO_IDENTITY_POOL_ID,
  },
  client: {
    URL: process.env.REACT_APP_CLIENT_URL,
  },
};

export default config;