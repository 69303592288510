import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { useSessionStorage } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import Dashboard from "../components/Dashboard";
import Lander from "../components/Lander";

export default function Home() {

  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function renderLander() {
    return (
      <Lander/>
    );
  }

  function renderDashboard() {
    return (
      <Dashboard/>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderDashboard() : renderLander()}
    </div>
  );

}