import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import config from '../config';
import { loadStripe } from "@stripe/stripe-js";
import SignupForm from "../components/SignupForm";

export default function Signup() {

  const stripePromise = loadStripe(config.STRIPE_KEY);

  const history = useHistory();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});

  return (
    <Elements
      stripe={stripePromise}>
      <SignupForm/>
    </Elements>
  );
}