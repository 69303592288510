import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import config from '../config';
import { Container, Jumbotron, Card, CardColumns, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import './CardViewer.scss';

export default function CardViewer() {

  const history = useHistory();
  const { id } = useParams();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});
  const [cardInfo, setCardInfo] = useState();

  async function getCardInfo() {
    const info = await API.get('enteraqt', `/cards/${userObj.id}/${id}`, {});
    return info;
  }

  useEffect(() => {
    async function onLoad() {
      setCardInfo(getCardInfo());
      window.addEventListener("message", (event) => {
        const height = event.data.height;
        if(height) {
          setViewerHeight(height)
        }
      }, false);
    }
    onLoad();
  }, []);

  function setViewerHeight(height) {
    var $clientViewer = document.querySelector('#client-viewer');
    $clientViewer.style.height = `${height}px`;
  }


  const cardURL = `${config.client.URL}/display.html?orgId=${userObj.id}&cardId=${id}&noVisit=true`;
  return (
    <>
      <iframe src={cardURL} id="client-viewer" />
    </>
  )

}