import reactCSS from 'reactcss';
import { TwitterPicker } from 'react-color';
import React, { useState, useEffect, useContext } from "react";
import { parseList, unparseList } from '../libs/functionsLib';
import './ColorPicker.scss';

export default function ColorPicker(params) {

  const [color, setColor] = useState(params.color || params.defaultColor);
  const [colors, setColors] = useState(params.brandColors);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const [styles, setStyles] = useState(reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${params.color || params.defaultColor}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  }));


  useEffect(() => {
    setColors(params.brandColors);
    setStyles(reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${color.hex}`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    }))
  }, [color, params.brandColors])

  function handleClick() {
    setDisplayColorPicker(!displayColorPicker);
  };

  function handleClose() {
    setDisplayColorPicker(false);
  }

  function handleChange(theColor) {
    params.onColorChange({
      target: {
        id: params.id,
        value: theColor.hex,
      },
    })
    setColor(theColor.hex);
  }

  function setNewColor(newColor) {
    setColor(newColor);
    params.onColorChange({
      target: {
        id: params.id,
        value: newColor,
      }
    });
  }

  return (
    <div className="color-picker">

      <div>
        <div style={styles.swatch} onClick={handleClick}>
          <div className="swatch" style={{ backgroundColor: color }}/>
        </div>
        {displayColorPicker ? <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <TwitterPicker color={color} onChange={handleChange} colors={parseList(colors)}/>
        </div> : null}

      </div>
    </div>
  );
}