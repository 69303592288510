import React, { useState, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import './ImageUploadField.scss';

export default function ImageUploadField(params) {

  const [cardInfo, setCardInfo] = useState(params.cardInfo);
  const [label, setLabel] = useState(params.label);
  const [id, setId] = useState(params.id);
  const [orgId, setOrgId] = useState(cardInfo.orgId);
  const [cardId, setCardId] = useState(cardInfo.cardId);
  const [fields, setFields] = useState(params.fields);

  let ASSETS_PATH;

  useEffect(() => {
    setFields(params.fields);
  });

  return (
    <>
      {
        cardInfo &&
        <Form.Group controlId={id} className="mb-3 image-upload">
          <Form.Label>{label}</Form.Label>
          <Form.Control type="file" />
          {
            fields && fields[id] &&
            <>
              <img className="image-preview" src={`${cardInfo?.assetPathRoot}/assets/${orgId}/${cardId}/${id}.image?ck=${new Date().getMilliseconds()}`} alt={id} width="30px"></img>
              <Form.Check
                type='checkbox'
                id={`${id}_delete`}
                label='Remove Image'
              />
            </>
          }
        </Form.Group>
      }
    </>
  )
}