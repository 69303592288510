import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage, } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { asyncForEach } from "../libs/functionsLib";
import { API, Auth } from "aws-amplify";
import config from '../config';
import { Row, Col, Container, Jumbotron, Card, CardColumns, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import flatten, { unflatten } from 'flat';
import random from 'random-ext';
import ColorPicker from './ColorPicker';
import { BlockPicker } from 'react-color';
import RichTextEditor from "./RichTextEditor";
import ImageUploadField from "./ImageUploadField";
import PaletteMaker from "./PaletteMaker";

import { HexColorPicker, HexColorInput } from "react-colorful";

export default function CardEditForm(params) {

  const history = useHistory();
  const { id } = useParams();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});
  const [cardInfo, setCardInfo] = useState(params.cardInfo);
  const [fields, handleFieldChange, setFields] = useFormFields(flatten(params.cardInfo));

  const [qrCode, setQRCode] = useState();

  const moveFragmentUp = params.moveFragmentUp;

  //let orgId, cardId, ASSETS_PATH;

  useEffect(() => {
    async function fetchQRCode() {
      const color = `${encodeURIComponent(fields['common.design.styles.qrCodeColor'] || '#000000')}`;
      const bgColor = `${encodeURIComponent(fields['common.design.styles.qrCodeBackgroundColor'] || '#ffffff')}`;
      const qrCodeURL = `/qrcode/${userObj.id}/${id}?color=${color}&bgColor=${bgColor}`;
      //console.log('colors', qrCodeURL)
      const data = await API.get('enteraqt', qrCodeURL, {});
      setQRCode(data);
    }
    fetchQRCode();
  }, [params.cardInfo, fields]);

  function downloadQRCode() {
    const $a = document.createElement("a"); //Create <a>
    $a.href = qrCode; //Image Base64 Goes here
    $a.download = `${fields.name} QR Code.png`; //File name Here
    $a.click(); //Downloaded file
  }

  async function handleSave(e) {
    e?.preventDefault();
    const $imageUploadComponents = document.querySelectorAll('.image-upload');
    params.handleSave(fields, $imageUploadComponents);
  }

  function drawRichTextForm(fragment, fragementIndex) {
    const id = fragment.id;
    const fragmentAddress = `fragments.${fragementIndex}`;
    const textFieldName = `${fragmentAddress}.content.text`;
    const colorFieldName = `${fragmentAddress}.design.styles.color`;
    const backgroundColorFieldName = `${fragmentAddress}.design.styles.backgroundColor`;
    return (
      <Container id={id} className={params.currentButton === id ? '' : 'hidden'} key={id}>
        <Card>
          <Card.Title>Rich Text</Card.Title>
          <Card.Body>
            You can add any Text that you want here. There are all the familiar formatting
            tools. Go wild!
          </Card.Body>
        </Card>
        <h3>Message</h3>
        <Row>
          <Form.Group controlId={textFieldName} size="lg">
            <RichTextEditor
              id={textFieldName}
              value={fields[textFieldName]}
              onContentChange={handleFieldChange}
            />

          </Form.Group>
        </Row>
        <h3>Colors</h3>
        <Row>
          <Form.Group as={Col} controlId={colorFieldName} size="lg">
            <Form.Label>Text</Form.Label>
            <div className="small">
              <ColorPicker
                id={colorFieldName}
                color={fields[colorFieldName] || fields["common.design.styles.color"]}
                brandColors={fields["common.design.styles.brandColors"]}
                onColorChange={handleFieldChange} />
            </div>
          </Form.Group>
          <Form.Group as={Col} controlId={backgroundColorFieldName} size="lg">
            <Form.Label>Background</Form.Label>
            <div className="small">
              <ColorPicker
                id={backgroundColorFieldName}
                color={fields[backgroundColorFieldName] || fields["common.design.styles.backgroundColor"]}
                brandColors={fields["common.design.styles.brandColors"]}
                onColorChange={handleFieldChange} />
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-3 fragment-form">
          <Col sm={9}></Col>
          <Col sm={3}>{
            fragment.after &&
            <Button
              onClick={() => moveFragmentUp(fragment)}
              className="move-fragment-up-button">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.0001 22.2877H13.0001V7.80237L16.2428 11.045L17.657 9.63079L12.0001 3.97394L6.34326 9.63079L7.75748 11.045L11.0001 7.80236V22.2877ZM18 3H6V1H18V3Z" fill="currentColor" /></svg>
            </Button>
          }
            <Button
              onClick={() => deleteFragment(fragment)}
              className="delete-fragment-button"
              variant="danger">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z" fill="currentColor" /><path d="M9 9H11V17H9V9Z" fill="currentColor" /><path d="M13 9H15V17H13V9Z" fill="currentColor" /></svg>
            </Button>
          </Col>
        </Row>
      </Container>
    )
  }

  function drawImageForm(fragment, fragementIndex) {
    const id = fragment.id;
    const fragmentAddress = `fragments.${fragementIndex}`;
    const imageFieldName = `${fragmentAddress}.content.image`;
    // const imageFieldDeleteName = `${imageFieldName}_delete`;
    return (
      <Container id={id} className={params.currentButton === id ? '' : 'hidden'} key={id}>
        <Card>
          <Card.Title>Image</Card.Title>
          <Card.Body>
            Upload an image to provide more information and visual interest!
          </Card.Body>
        </Card>
        <h3>Image</h3>
        <Row className="mb-3 fragment-form">
          <Col sm={9}>
            <ImageUploadField
              id={imageFieldName}
              cardInfo={cardInfo}
              fields={fields}
            />
          </Col>
          <Col sm={3}>{
            fragment.after &&
            <Button
              onClick={() => moveFragmentUp(fragment)}
              className="move-fragment-up-button">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.0001 22.2877H13.0001V7.80237L16.2428 11.045L17.657 9.63079L12.0001 3.97394L6.34326 9.63079L7.75748 11.045L11.0001 7.80236V22.2877ZM18 3H6V1H18V3Z" fill="currentColor" /></svg>
            </Button>
          }
            <Button
              onClick={() => deleteFragment(fragment)}
              className="delete-fragment-button"
              variant="danger">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z" fill="currentColor" /><path d="M9 9H11V17H9V9Z" fill="currentColor" /><path d="M13 9H15V17H13V9Z" fill="currentColor" /></svg>
            </Button>
          </Col>
        </Row>
      </Container>
    )
  }

  function drawFragmentForms() {
    const numberOfFragments = cardInfo?.fragments?.length;
    const fragments = cardInfo.fragments;
    return (
      <>
        {
          fragments.map((fragment, i) => {
            switch (fragment.type) {
              case 'richtext': {
                return drawRichTextForm(fragment, i);
              };
              case 'image': {
                return drawImageForm(fragment, i);
              };
              default: {
                return '';
              }
            }

          })
        }
      </>
    )
  }

  async function deleteFragment(fragment) {
    if (window.confirm('Are you sure you want to delete this Fragment?')) {
      if (fragment.type === 'image') {
        id = fragment.id;
        await API.del('enteraqt', `/assets/${userObj.id}/${cardInfo.cardId}/${id}`, {});
      }
      const cardInfo = await API.del('enteraqt', `/cards/${userObj.id}/${id}/fragments/${fragment.id}`, {
        body: fragment,
      });
      await params.afterSave(cardInfo);
      console.log(cardInfo)
      params.handleFragmentSelect('common');
    }
  }

  const handleColorChange = ({ hex }) => console.log(hex)

  return (
    <>
      {/* {console.log('fields', fields)} */}
      {/* Need to implement this http://bootstrap-colorselector.flaute.com */}
      <Form id="content" onSubmit={handleSave}>
        <Container id="common" className={params.currentButton === 'common' ? '' : 'hidden'}>
          <Card>
            <Card.Title>General Settings</Card.Title>
            <Card.Body>
              These settings determine the General properties of the Card
              and will provide things like colors and fonts if they aren't defined specifically
              by the Section or Fragment.
            </Card.Body>
          </Card>
          <h3>Card Name</h3>
          <Form.Group controlId="name" size="lg">
            <Form.Control
              autoFocus
              type="text"
              value={fields.name}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <hr />
          <Row className="mb-3" >
            <Form.Group as={Col} controlId="common.design.styles.brandColors" size="lg">
              <Form.Label>Brand Colors</Form.Label>
              <div className="small">
                <PaletteMaker
                  id="common.design.styles.brandColors"
                  value={fields["common.design.styles.brandColors"]}
                  onChange={handleFieldChange} />
              </div>
            </Form.Group>
          </Row>
          <hr />
          <h3>Default Colors</h3>
          <Row className="mb-3" >
            <Form.Group as={Col} controlId="common.design.styles.color" size="lg">
              <Form.Label>Text</Form.Label>
              <div className="small">
                <ColorPicker
                  id="common.design.styles.color"
                  color={fields["common.design.styles.color"]}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
            <Form.Group as={Col} controlId="common.design.styles.backgroundColor" size="lg">
              <Form.Label>Background</Form.Label>
              <div className="small">
                <ColorPicker
                  id="common.design.styles.backgroundColor"
                  color={fields["common.design.styles.backgroundColor"]}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
          </Row>
          <hr />
          <h3>QR Code</h3>
          <Row className="mb-3" >
            <Form.Group as={Col} controlId="common.design.styles.qrCodeColor" size="lg">
              <Form.Label>Code</Form.Label>
              <div className="small">
                <ColorPicker
                  id="common.design.styles.qrCodeColor"
                  color={fields["common.design.styles.qrCodeColor"] || '#000000'}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
            <Form.Group as={Col} controlId="common.design.styles.qrCodeBackgroundColor" size="lg">
              <Form.Label>Background</Form.Label>
              <div className="small">
                <ColorPicker
                  id="common.design.styles.qrCodeBackgroundColor"
                  color={fields["common.design.styles.qrCodeBackgroundColor"] || '#ffffff'}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
          </Row>
          <Row>
            <div className="qr-code">
              {
                qrCode &&
                <>
                  <img src={qrCode} onClick={downloadQRCode} alt="QR Code" width="200" height="200" />
                  <Button onClick={downloadQRCode} size="sm">Download QR Code</Button>
                </>
              }
            </div>
          </Row>
        </Container>

        <Container id="responses" className={params.currentButton === 'responses' ? '' : 'hidden'}>
          <Card>
            <Card.Title>Responses</Card.Title>
            <Card.Body>
              Here you can configure if and what you want to say in response to getting a contact.
            </Card.Body>
          </Card>
          <Form.Label>Thank You Pop-Up Message</Form.Label>
          <RichTextEditor
            id="responses.thanksoverlay.content.message"
            value={fields['responses.thanksoverlay.content.message']}
            onContentChange={handleFieldChange}
          />
        </Container>



        <Container id="header" className={params.currentButton === 'header' ? '' : 'hidden'}>
          <Card>
            <Card.Title>Header</Card.Title>
            <Card.Body>
              This is where the top part of the Card is configured. For example, you can add
              your company's logo.
            </Card.Body>
          </Card>
          <ImageUploadField
            id="header.content.logo"
            label="Logo"
            cardInfo={cardInfo}
            fields={fields}
          />
          <hr />
          <Row className="mb-3">
            <Form.Group as={Col} controlId="header.design.styles.backgroundColor" size="lg">
              <Form.Label>Background Color</Form.Label>
              <div className="small">
                <ColorPicker
                  id="header.design.styles.backgroundColor"
                  color={fields["header.design.styles.backgroundColor"] || fields["common.design.styles.backgroundColor"]}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
          </Row>
        </Container>

        <Container id="welcome" className={params.currentButton === 'welcome' ? '' : 'hidden'}>
          <Card>
            <Card.Title>Welcome</Card.Title>
            <Card.Body>
              This is your opportunity to say "Hello!" to your visitors. You can add formatted text,
              an image, or both. Here's where you shine!
            </Card.Body>
          </Card>
          <h3>Message</h3>
          <Form.Group controlId="welcome.content.message" size="lg">
            <RichTextEditor
              id="welcome.content.message"
              value={fields['welcome.content.message']}
              onContentChange={handleFieldChange}
            />
          </Form.Group>
          <h3>Colors</h3>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="welcome.design.styles.color" size="lg">
              <Form.Label>Text</Form.Label>
              <div className="small">
                <ColorPicker
                  id="welcome.design.styles.color"
                  color={fields["welcome.design.styles.color"] || fields["common.design.styles.color"]}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
            <Form.Group as={Col} controlId="welcome.design.styles.backgroundColor" size="lg">
              <Form.Label>Background</Form.Label>
              <div className="small">
                <ColorPicker
                  id="welcome.design.styles.backgroundColor"
                  color={fields["welcome.design.styles.backgroundColor"] || fields["common.design.styles.backgroundColor"]}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
          </Row>
          <h3>Image</h3>
          <ImageUploadField
            id="welcome.content.image"
            cardInfo={cardInfo}
            fields={fields}
          />
        </Container>

        <Container id="contactform" className={params.currentButton === 'contactform' ? '' : 'hidden'}>
          <Card>
            <Card.Title>Contact Form</Card.Title>
            <Card.Body>
              You've enticed them to reach out! This is where they enter their information.
              You can configure the colors of the Form and Button to match your branding.
            </Card.Body>
          </Card>
          <h3>Form Colors</h3>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="contactform.design.styles.color" size="lg">
              <Form.Label>Text</Form.Label>
              <div className="small">
                <ColorPicker
                  id="contactform.design.styles.color"
                  color={fields["contactform.design.styles.color"] || fields["common.design.styles.color"]}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
            <Form.Group as={Col} controlId="contactform.design.styles.backgroundColor" size="lg">
              <Form.Label>Background</Form.Label>
              <div className="small">
                <ColorPicker
                  id="contactform.design.styles.backgroundColor"
                  color={fields["contactform.design.styles.backgroundColor"] || fields["common.design.styles.backgroundColor"]}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
          </Row>
          <h3>Send Button Colors</h3>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="contactform.design.styles.sendbutton.color" size="lg">
              <Form.Label>Text</Form.Label>
              <div className="small">
                <ColorPicker
                  id="contactform.design.styles.sendbutton.color"
                  color={fields["contactform.design.styles.sendbutton.color"] || fields["common.design.styles.color"]}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
            <Form.Group as={Col} controlId="contactform.design.styles.sendbutton.backgroundColor" size="lg">
              <Form.Label>Background</Form.Label>
              <div className="small">
                <ColorPicker
                  id="contactform.design.styles.sendbutton.backgroundColor"
                  color={fields["contactform.design.styles.sendbutton.backgroundColor"] || fields["common.design.styles.backgroundColor"]}
                  brandColors={fields["common.design.styles.brandColors"]}
                  onColorChange={handleFieldChange} />
              </div>
            </Form.Group>
          </Row>
        </Container>

        {
          cardInfo &&
          drawFragmentForms()
        }

        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={params.isLoading}
        >
          Save
        </LoaderButton>
      </Form>
    </>
  )
}