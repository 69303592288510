import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { downloadFile } from "../libs/functionsLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import config from '../config';
import { Container, Table, Nav, Button, Popover, } from "react-bootstrap";
import { DateTime } from 'luxon';
import { parse } from 'json2csv';

export default function Contacts() {

  const history = useHistory();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});
  const [cards, setCards] = useSessionStorage('cards', []);
  const [contacts, setContacts] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function onLoad() {

    }
    onLoad();
  }, []);

  async function loadContacts(retries = 5) {
    setLoading(true);
    try {
      const $daysSelect = document.querySelector('#days-select');
      const contactsData = await API.get('enteraqt', `/organizations/${userObj.id}/contacts?days=${$daysSelect?.value || 30}`, {});
      setContacts(contactsData);
      setLoading(false);
      return;
    } catch (err) {
      if (retries === 0) {
        throw new Error(err);
      } else {
        --retries;
        setTimeout(() => loadContacts(retries), 10000);
      }
    }
    setLoading(false);
  }

  function getContactsCSV() {
    const fields = ['name', 'mobile', 'email', 'notes', 'dateTime', 'cardName', 'tags',];
    const opts = { fields };
    try {
      const csv = parse(contacts, opts);
      console.log(csv);
      downloadFile('contacts.csv', csv)
    } catch (err) {
      console.error(err);
    }
  }

  function getTable(values) {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Notes</th>
            <th>Date</th>
            <th>Card</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody>
          {
            values.map((contact, i) => {
              const card = cards.find(card => card.cardId === contact.cardId);
              const date = DateTime.fromISO(contact.dateTime).toISODate();
              return (
                <tr key={i}>
                  <td>{++i}</td>
                  <td>{contact.name}</td>
                  <td>{contact.mobile}</td>
                  <td>{contact.email}</td>
                  <td>{contact.notes}</td>
                  <td>{date}</td>
                  <td>{card?.name || ''}</td>
                  <td>{contact.tags}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    )
  }

  return (
    <>
      <Nav>
        <Nav.Item>
          <Button
            onClick={loadContacts}
          >
            <span className={(loading && !contacts && contacts?.length !== 0) ? 'spinner-border spinner-border-sm' : ''} role="status" aria-hidden="true"></span>
            Load Contacts
          </Button>
        </Nav.Item>
        <Nav.Item>
          <Form.Control as="select" id="days-select">
            <option value="7">7 days</option>
            <option value="30">30 days</option>
            <option value="60">60 day</option>
            <option value="90">90 days</option>
            <option value="180">6 months</option>
            <option value="365">1 year</option>
          </Form.Control>
        </Nav.Item>
        {
          contacts &&
          <Nav.Item>
            <Button
              onClick={getContactsCSV}
            >
              Download CSV
            </Button>
          </Nav.Item>
        }
      </Nav>
      {
        contacts &&
        getTable(contacts)
      }
    </>
  )
}