import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import config from '../config';
import { Container } from "react-bootstrap";

export default function NewCard() {

  const history = useHistory();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});
  const [isLoading, setIsLoading] = useState(null);

  const [fields, handleFieldChange] = useFormFields({
    cardName: '',
  });

  function validateForm() {
    return (
      fields.cardName !== ''
    );
  }

  async function createCard(cardInfo) {
    return await API.post('enteraqt', `/organizations/${userObj.id}/cards`, {
      body: cardInfo
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const cardInfo = {
      name: fields.cardName,
      orgId: userObj.id,
      "common": {
        "design": {
          "styles": {
            "font": "'San Serif'",
            "color": "#222222",
            "backgroundColor": "#F1F1F1"
          }
        }
      },
      "header": {
        "content": {
          "logo": ""
        },
        "design": {
          "styles": {
            "backgroundColor": "#FFFFFF"
          }
        }
      },
      "welcome": {
        "content": {
          "message": "",
          "image": ""
        },
        "design": {
          "styles": {
            "color": "",
            "backgroundColor": ""
          }
        }
      },
      "contactform": {
        "design": {
          "styles": {
            "backgroundColor": "gray",
            "sendbutton": {
              "color": "#FFFFFF",
              "backgroundColor": "#383845"
            }
          }
        }
      },
      "fragments": [],
    }
    await createCard(cardInfo);
    history.push('/');
  }

  return (
    <Container className="text-center">
      <h1>Name your new Card</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="cardName" size="lg">
          <Form.Label>Card Name</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={fields.cardName}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Continue
        </LoaderButton>
      </Form>
    </Container>
  );
}