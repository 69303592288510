import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import config from '../config';
import "./CardList.scss";
import { Container, Jumbotron, Card, CardColumns, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import CardItem from "./CardItem";

export default function CardList(params) {

  const history = useHistory();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});
  const [cards, setCards] = useSessionStorage('cards', []);
  const [isLoading, setLoading] = useState(false);

  const { handleTabChange } = params;

  useEffect(() => {
    async function onLoad() {
      const cards = await getCards();
      setCards(cards);
    }
    onLoad();
  }, []);

  async function getCards() {
    return API.get('enteraqt', '/cards', {
      'queryStringParameters': {
        orgId: userObj.id,
      }
    });
  }

  function addCard() {
    history.push('/newcard');
  }

  async function copyCard(cardId) {
    setLoading(true);
    const copyCardURL = `/cards/${userObj.id}/${cardId}`;
    const data = await API.post('enteraqt', copyCardURL, {});
    const cards = await getCards();
    setCards(cards);
    setLoading(false);
  }

  async function deleteCard(cardId) {
    setLoading(true);
    const deleteCardURL = `/cards/${userObj.id}/${cardId}`;
    const data = await API.del('enteraqt', deleteCardURL, {});
    const cards = await getCards();
    setCards(cards);
    setLoading(false);
  }

  return (
    <Container className="card-manager">
      <ListGroup className="card-list">
        {
          cards.map((card, i) => {
            return (
              <CardItem id={card.cardId} title={card.name} handleCopyClick={copyCard} handleTabChange={handleTabChange} handleDeleteClick={deleteCard} isLoading={isLoading} key={card.cardId} />
            );
          })
        }
      </ListGroup>
      <div className="d-grid gap-2">
        <Button variant="primary" size="lg" className="add-card" onClick={addCard}>
          Add Card
        </Button>
      </div>
    </Container>
  )
}