import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { downloadFile, asyncFilter } from "../libs/functionsLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import config from '../config';
import { Container, Table, Nav, Button, Popover, Spinner,} from "react-bootstrap";
import { DateTime } from 'luxon';
import { parse } from 'json2csv';
import { ResponsiveLine } from '@nivo/line'
import { ConsoleLogger } from "@aws-amplify/core";

export default function Reports() {

  const history = useHistory();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});
  const [visits, setVisits] = useState();
  const [visitData, setVisitData] = useState();
  const [loading, setLoading] = useState(false);

  const now = DateTime.now();
  const begining = now.minus({ days: 30, });

  let maxVisits = 0;



  useEffect(() => {
    async function onLoad() {
      if (!visitData) {
        await loadVisits();
      } else {
        const visitTemplate = await getDataTemplate();
        setVisits(visitTemplate);
      }
    }
    onLoad();
  }, [visitData]);

  async function getDataTemplate() {

    const visitTemplate = {
      id: 'visits',
      "color": "hsl(347, 70%, 50%)",
    };
    let data = [];
    for (let i = 29; i >= 0; i--) {
      const date = now.minus({ days: i });
      const dateString = date.toISODate();
      const filteredVisits = [];
      for (let i in visitData) {
        const visit = visitData[i];
        if (visit.dateTime.startsWith(dateString)) { //Need to use Luxon to do this comparison since the DateTime is UTC
          filteredVisits.push(visit);
        }
      };
      if (filteredVisits.length > maxVisits)
        maxVisits = filteredVisits.length;
      const datum = {
        x: date.toFormat('LLL dd'),
        y: (filteredVisits) ? filteredVisits.length : 0,
      }
      data.push(datum);
    }
    visitTemplate.data = data;
    return [visitTemplate];
  }

  async function loadVisits() {
    setLoading(true);
    let retries = 10;
    try {
      const visitData = await API.get('enteraqt', `/organizations/${userObj.id}/visits?startDate=${begining}&endData=${now}`, {});
      setVisitData(visitData);
      setLoading(false);
      return visitData;
    } catch (err) {
      if (retries === 0) {
        throw new Error(err);
      } else {
        --retries;
        setTimeout(loadVisits, 5000);
      }
    }
  }

  function drawGraph() {
    return (
      <div id="line-graph">
        <ResponsiveLine
          data={visits}
          margin={{ top: 50, right: 16, bottom: 60, left: 50 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
          }}
          yFormat=" >-0.0d"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 70,
            //legend: 'date',
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisLeft={{
            orient: 'left',
            format: e => Math.floor(e) === e && e,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '# of visits',
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
        />
      </div>
    )
  }

  return (
    <>
      {
        !loading && visits &&
        drawGraph()
      }
      {
        loading && !visits &&
        <Spinner animation="border" />
      }
    </>
  )
}