import { HexColorPicker, HexColorInput } from "react-colorful";
import React, { useState, useEffect, useContext } from "react";
import { Button, CloseButton } from "react-bootstrap";
import { parseList, unparseList } from '../libs/functionsLib';
import './PaletteMaker.scss';

export default function PaletteMaker(params) {

  const [id, setId] = useState(params.id);
  const [colors, setColors] = useState(parseList(params.value) || [])
  const [color, setColor] = useState(params.defaultColor || '#000000');

  useEffect(() => {
    // setColors(params.fields[params.id])
    // console.log('colors', colors)
    // console.log('params colors', params.value)
  }, [])

  function handleAddColor() {
    if (!colors.includes(color)) {
      colors.push(color);
    }
    params.onChange({
      target: {
        id,
        value: unparseList(colors),
      }
    });
  }

  function handleRemoveColor(theColor) {
    if (window.confirm('Are you sure you want to delete this Color?')) {
      if (colors.includes(theColor)) {
        const filteredColors = colors.filter(function (color, index, arr) {
          return color !== theColor;
        });
        setColors(filteredColors);
        params.onChange({
          target: {
            id,
            value: unparseList(filteredColors),
          }
        });
      }
    }
  }

  function handleColorChange(e) {
    setColor(e.target.value)
  }

  return (
    <div className="palette-maker">
      <input
        type="color"
        id={id}
        onChange={handleColorChange}
      />
      <div className="color-list">
        {
          colors.map((color, i) => {
            return (
              <div className="color" style={{ backgroundColor: color }} key={color}>
                {/* {color} */}
                <CloseButton className="p-3" onClick={() => handleRemoveColor(color)} />
              </div>
            );
          })
        }
      </div>
      <Button
        onClick={handleAddColor}
        variant="secondary"
      >
        Add
      </Button>
    </div>
  );
}