import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import config from '../config';
import { Container, Jumbotron, Card, CardColumns, Tabs, Tab } from "react-bootstrap";
import CardList from "./CardList";
import Contacts from "./Contacts";
import Reports from "./Reports";
import './Dashboard.scss';

export default function Dashboard() {

  const history = useHistory();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});
  const [activeTab, setActiveTab] = useState('home');

  function handleTabChange(tabName) {
    setActiveTab(tabName)
  }

  return (
    <Tabs activeKey={activeTab} id="admin" className="mb-3" onSelect={handleTabChange}>
      <Tab eventKey="home" title="Cards" key="home">
        <CardList handleTabChange={handleTabChange}/>
      </Tab>
      <Tab eventKey="contacts" title="Contacts" key="contacts">
        <Contacts/>
      </Tab>
      <Tab eventKey="reports" title="Reports" key="reports">
        <Reports/>
      </Tab>
    </Tabs>
  )
}