import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import LoaderButton from "./LoaderButton";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import random from 'random-ext';
import './SignupForm.scss';

export default function SignupForm() {

  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();
  const [newUser, setNewUser] = useState(null);
  const [stripeToken, setStripeToken] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [userObj, setUserObj] = useSessionStorage('userInfo', {});

  const [fields, handleFieldChange] = useFormFields({
    email: '',
    mobile: '',
    givenName: '',
    familyName: '',
    organizationName: '',
    password: '',
    confirmPassword: '',
    confirmationCode: '',
  });

  const [isCardComplete, setIsCardComplete] = useState(false);

  useEffect(() => {

  }, []);

  function validateForm() {
    return (
      stripe &&
      elements &&
      fields.givenName !== '' &&
      fields.familyName !== '' &&
      fields.organizationName !== '' &&
      fields.email !== '' &&
      fields.mobile !== '' &&
      fields.password !== '' &&
      fields.confirmPassword !== '' &&
      isCardComplete
    );
  }

  function saveUserInfo(userInfo, stripeUser) {
    userInfo['stripeId'] = stripeUser.id;
    return API.post("enteraqt", "/organizations", {
      body: userInfo
    });
  }

  function saveBillingInfo(details) {
    return API.post('enteraqt', '/billing', {
      body: details
    });
  }

  function sanitizePhone(number) {
    number = number.replace(/[^\d\+]/g, "");
    if (number.length < 11)
      number = `1${number}`;
    if (!number.startsWith('+'))
      number = `+${number}`;

    return number;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    const mobileNumber = sanitizePhone(fields.mobile);

    // First create Congnito User
    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          given_name: fields.givenName,
          family_name: fields.familyName,
          email: fields.email,
          phone_number: mobileNumber,
        }
      });

      // const cogUserId = newUser.userSub;
      // Get Stripe Token
      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);
      setStripeToken(token);

      setNewUser(newUser);

      // // Create Stripe User
      // const stripeUser = await saveBillingInfo({
      //   source: token.id,
      //   email: fields.email,
      // });


      // const userObj = {
      //   id: cogUserId,
      //   givenName: fields.givenName,
      //   familyName: fields.familyName,
      //   email: fields.email,
      //   mobile: fields.mobile,
      //   organizationName: fields.organizationName,
      // };

      // const { id } = await saveUserInfo(userObj, stripeUser);
      // userObj.id = id;
      // setUserObj(userObj);
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Text muted>Please check your email for the code.</Form.Text>
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </Form>
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);

      const cogUserId = newUser.userSub;

      // Create Stripe User
      const stripeUser = await saveBillingInfo({
        source: stripeToken.id,
        email: fields.email,
      });



      const userObj = {
        id: random.restrictedString([
          random.CHAR_TYPE.UPPERCASE,
          random.CHAR_TYPE.LOWERCASE,
          random.CHAR_TYPE.NUMERIC,
        ], 10, 10),
        cognitoId: cogUserId,
        givenName: fields.givenName,
        familyName: fields.familyName,
        email: fields.email,
        mobile: fields.mobile,
        organizationName: fields.organizationName,
      };


      const { id } = await saveUserInfo(userObj, stripeUser);
      setUserObj(userObj);
      userHasAuthenticated(true);
      history.push("/");

    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }


  function renderForm() {
    return (
      <>
        <h1>Sign up!</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="givenName" size="lg">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              value={fields.givenName}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="familyName" size="lg">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              value={fields.familyName}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="organizationName" size="lg">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              value={fields.organizationName}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="email" size="lg">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="mobile" size="lg">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              autoFocus
              type="tel"
              value={fields.mobile}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="password" size="lg">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
            />
            <Form.Text id="passwordHelpBlock" muted>
              Must be 8-20 characters long.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="confirmPassword" size="lg">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              onChange={handleFieldChange}
              value={fields.confirmPassword}
            />
          </Form.Group>
          <Form.Label>Credit Card Info</Form.Label>
          <CardElement
            className="card-field form-control"
            onChange={(e) => setIsCardComplete(e.complete)}
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#495057",
                  fontFamily: "'Open Sans', sans-serif",
                  border: '1px solid red',
                  padding: '16px',
                },
              },
            }}
          />
          <LoaderButton
            block
            size="lg"
            type="submit"
            className="signup-button"
            variant="success"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Signup
          </LoaderButton>
        </Form>
      </>
    )
  };

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  )
}