import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import config from '../config';
import { retry } from '../libs/functionsLib';
import { Container, Jumbotron, Card, CardColumns, ListGroup, ListGroupItem, Button, ButtonGroup, Badge, Figure } from "react-bootstrap";

export default function CardItem(params) {

  const history = useHistory();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});

  const { id, title, isLoading, handleTabChange } = params;


  const [visitCount, setVisitCount] = useState(null);

  useEffect(() => {
    getCount();
  }, []);

  function handleEditClick() {
    history.push(`/card/${id}`);
  }

  async function handleCopyClick() {
    await params.handleCopyClick(id);
  }

  async function handleDeleteClick() {
    if (window.confirm('Are you sure you want to delete this Card?')) {
      await params.handleDeleteClick(id);
    }
  }

  async function getCount() { //TODO: abstract this out (figure out why retry() in libs doesn't work)
    let retries = 5;
    try {
      const count = await API.get('enteraqt', `/organizations/${userObj.id}/cards/${id}/visits/count`, {});
      setVisitCount(count);
      return;
    } catch (err) {
      if (retries === 0) {
        throw new Error(err);
      } else {
        --retries;
        setTimeout(getCount, 10000);
      }
    }
  }

  function handleVisitButtonClick() {
    handleTabChange('reports');
  }

  return (
    <ListGroupItem>
      <Container>
        <div className="item-content">
          <div className="basic-info">

            <p className="card-title">{title}</p>
          </div>
          <div className="admin-buttons">
            <div>
              <ButtonGroup>
                <Button
                  onClick={!isLoading ? handleCopyClick : null}
                  variant="secondary"
                  disabled={isLoading}
                >
                  Copy
                </Button>
                <Button
                  onClick={!isLoading ? handleDeleteClick : null}
                  variant="danger"
                  disabled={isLoading}
                >
                  Delete
                </Button>
              </ButtonGroup>
              <Button
                onClick={handleVisitButtonClick}
                className="visits-button">
                <span className={(!visitCount && visitCount !== 0) ? 'spinner-border spinner-border-sm' : ''} role="status" aria-hidden="true"></span>
                {visitCount} Visit(s)
              </Button>
            </div>
            <Button
              onClick={!isLoading ? handleEditClick : null}
              size="lg"
              disabled={isLoading}
            >
              Edit
            </Button>
          </div>
        </div>
      </Container>
    </ListGroupItem>
  )
}