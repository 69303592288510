import React, { useState } from "react";
import { Container, Jumbotron, Card, CardColumns } from "react-bootstrap";

export default function Lander() {
  return (
    <Jumbotron fluid>
      <Container>
        <h1>enteraqt</h1>
        <p className="text-muted">Connect with your customers!</p>
      </Container>
    </Jumbotron>
  )
}