import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import LoaderButton from "./LoaderButton";
import { useFormFields, useLocalStorage, useSessionStorage } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";

export default function SettingsForm() {

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});
  const [isLoading, setIsLoading] = useState(null);
  const history = useHistory();

  const [fields, handleFieldChange] = useFormFields({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  useEffect(() => {

  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    await changePassword();
    setIsLoading(false);
  }

  function validatePassword() {
    return (
      fields.oldPassword !== '' &&
      fields.newPassword !== '' &&
      fields.confirmNewPassword !== '' &&
      fields.newPassword === fields.confirmNewPassword
    );
  }

  async function changePassword() {
    if (validatePassword()) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, fields.oldPassword, fields.newPassword);
        fields.oldPassword = '';
        fields.newPassword = '';
        fields.confirmNewPassword = '';
        alert('Your password was successfully changed');
      } catch (ew) {
        alert('There was an error. Please try again later.');
      }
    }
  }

  return (
    <>
      <h1>Settings</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="oldPassword" size="lg">
          <Form.Label>Current Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.oldPassword}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="newPassword" size="lg">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.newPassword}
            onChange={handleFieldChange}
          />
          <Form.Text id="passwordHelpBlock" muted>
            Must be 8-20 characters long.
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="confirmNewPassword" size="lg">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmNewPassword}
          />
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          className="signup-button"
          variant="success"
          isLoading={isLoading}
          disabled={!validatePassword()}
        >
          Save
        </LoaderButton>
      </Form>
    </>
  )

}